import React from 'react';
import './styles.css';

function Privacypolicy() {
  return (
    <div className="page">
    <header className="header">
       <div className="container"> 
         <nav className="navbar">
               <ul className="nav wrap">
                 <li><a href="/">About</a></li>
                 <li><a href="/work">Work</a></li>
                 <li><a href="/clients">Clients</a></li>
                 <li><a href="/contact">Contact</a></li>
                 <li><a className="active" href="/privacypolicy">Privacy Policy</a></li>
               </ul>
         </nav>
       </div>
     </header>      
     <div className="clear"></div>
     
     <section className="background" style="padding:20px;">
       <div className="container">
         <h3>Privacy Policy</h3>
         <div className="row row-2">
           <div className="col col-2">
             <article className="content">
               <div className="contentbody">
               <p className="in">My website may contain links to other websites. I am not responsible for the privacy practices of any such other website (whether accessed through an advertisement, service, or content link) and urge you to review such practices prior to submitting any personal information to such websites.</p>
               </div>
            </article>
           </div>
         </div>
       </div>
     </section>
     
     <footer className="footer">
       <div className="container">
         <div className="footer-inner">
           <p className="in2"><span>Brett O'Connor Designs</span>&nbsp;<span>&copy;</span>&nbsp;2019</p>
         </div>
       </div>
     </footer>
</div>
  );
}

export default Privacypolicy;
