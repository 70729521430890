import React from 'react';
import './styles.css';

function Contact() {
  return (
    <div className="page">
    <header className="header">
       <div className="container"> 
         <nav className="navbar">
               <ul className="nav wrap">
                 <li><a href="/">About</a></li>
                 <li><a href="/work">Work</a></li>
                 <li><a href="/clients">Clients</a></li>
                 <li><a className="active" href="/contact">Contact</a></li>
                 <li><a href="/privacypolicy">Privacy Policy</a></li>
               </ul>
         </nav>
       </div>
     </header>      
     <div className="clear"></div>
     
     <section className="background" style="padding:20px;">
       <div className="container">
         <h3>Contact Form</h3>
         <div className="row row-2">
           <div className="col col-2">
             <article className="content">
               <div className="contentbody">
               <form action="mailto:mail.brettoconnor@gmail.com" method="post">
                    <fieldset>   
                    <label>NAME</label>                                         
                        <div className="rowElem">                                                                    					
                           <input type="text" name="name" />                        
                        </div>
                           <label>EMAIL</label>
                        <div className="rowElem">                                                                    					
                           <input type="text" name="email" />
                        </div>       
                           <label>PHONE NUMBER</label>                            
                        <div className="rowElem">                                                                    					
                           <input type="text" name="phone" />
                        </div>
                           <label>MESSAGE</label>
                        <div className="textarea-box">                                                                   						
                           <textarea cols="15" rows="8" name="message"></textarea>
                        </div>
                        <div>
                           <br />
                           <input type="submit" class="submit" value="SUBMIT" />
                       </div>                                                
                    </fieldset>
               </form>    
               </div>
            </article>
         </div>
       </div>
       </div>
     </section>
     
     <footer className="footer">
       <div className="container">
         <div className="footer-inner">
           <p className="in2"><span>Brett O'Connor Designs</span>&nbsp;<span>&copy;</span>&nbsp;2019</p>
         </div>
       </div>
     </footer>
</div>
  );
}

export default Contact;
