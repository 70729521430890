import React from 'react';
import './styles.css';

function Work() {
    return (
      <div className="page">
      <header className="header">
         <div className="container"> 
           <nav className="navbar">
                 <ul className="nav wrap">
                   <li><a href="/">About</a></li>
                   <li><a className="active" href="/work">Work</a></li>
                   <li><a href="/clients">Clients</a></li>
                   <li><a href="/contact">Contact</a></li>
                   <li><a href="/privacypolicy">Privacy Policy</a></li>
                 </ul>
           </nav>
         </div>
       </header>      
       <div className="clear"></div>
      
       <section className="background" style="padding:20px;">
         <div className="container">
           <h3>React.js</h3>
           <div className="row row-2">
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/5minreactjstutorial.html" target="new"><img src="images/5minreactjstutorial.jpg" alt="React - App" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>App</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>React.js</span>
                   </div>
                 </div>
               </article>
             </div>
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/reactcomponentfromscratch.html" target="new"><img src="images/reactcomponentfromscratch.jpg" alt="React - Component From Scratch" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>React</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>React Component From Scratch</span>
                   </div>
                 </div>
               </article>
             </div>
           </div>
         </div>
       </section>

       <section className="background" style="padding:20px;">
         <div className="container">
           <h3>JavaScript</h3>
           <div className="row row-2">
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/forms.html" target="new"><img src="images/forms.jpg" alt="javascript - forms" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Beautilful Bouquets</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>JavaScript</span>
                   </div>
                 </div>
               </article>
             </div>
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/calendar.html" target="new"><img src="images/calendar.jpg" alt="javascript - calendar" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Game Schedule</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>JavaScript</span>
                   </div>
                 </div>
               </article>
             </div>
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/sidebar.html" target="new"><img src="images/sidebar.jpg" alt="javascript - sidebar menu" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Sidebar Menu</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>JavaScript</span>
                   </div>
                 </div>
               </article>
             </div>
           </div>
         </div>
       </section>

       <section className="background" style="padding:20px;">
         <div className="container">
           <h3>jQuery</h3>
           <div className="row row-2">
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/calendarjquery.html" target="new"><img src="images/calendar.jpg" alt="jquery - calendar" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Game Schedule</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>jQuery</span>
                   </div>
                 </div>
               </article>
             </div>
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/calculatejquery.html" target="new"><img src="images/calculate.jpg" alt="jquery - calculate" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Website Calculations</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>jQuery</span>
                   </div>
                 </div>
               </article>
             </div>
           </div>
         </div>
       </section>

       <section className="background" style="padding:20px;">
         <div className="container">
           <h3>HTML/JavaScript/jQuery</h3>
           <div className="row row-2">
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/htmljavascriptjquery.html" target="new"><img src="images/htmljavascriptjquery.jpg" alt="template - html/javascript/jquery" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Template</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>HTML/JavaScript/jQuery</span>
                   </div>
                 </div>
               </article>
             </div>
           </div>
         </div>
       </section>
       
       <section className="background" style="padding:20px;">
         <div className="container">
           <h3>JavaScript / jQuery / Ajax</h3>
           <div className="row row-2">
             <div className="col col-3">
               <article className="content">
               <a className="view" href="work/country.html" target="new"><img src="images/country.jpg" alt="javascript / jquery / ajax - countries" width="418" height="315" /></a>
                 <div className="contenttitle">
                   <h5>Countries</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>JavaScript / jQuery / Ajax</span>
                   </div>
                 </div>
               </article>
             </div>
           </div>
         </div>
       </section>
       
       <section className="background" style="padding:20px;">
         <div className="container">
           <h3>WordPress</h3>
           <div className="row row-2">
             <div className="col col-3">
               <article className="content">
               <img src="images/wordpressbrettoconnordesigns.jpg" alt="wordpress - brett o'connor designs" width="418" height="315" />
                 <div className="contenttitle">
                   <h5>Brett O'Connor Designs</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>WordPress</span>
                   </div>
                 </div>
               </article>
             </div>
             <div className="col col-3">
               <article className="content">
               <img src="images/wordpresscaretakerdesigns.jpg" alt="wordpress - caretakerdesigns" width="418" height="315" />
                 <div className="contenttitle">
                   <h5>Caretaker Designs</h5>
                 </div>
                 <div className="subcontent">
                   <div className="subtitle">
                       <span>WordPress</span>
                   </div>
                 </div>
               </article>
             </div>
           </div>
         </div>
       </section>
       
       <footer className="footer">
         <div className="container">
           <div className="footer-inner">
             <p className="in2"><span>Brett O'Connor Designs</span>&nbsp;<span>&copy;</span>&nbsp;2019</p>
           </div>
         </div>
       </footer>
  </div>
    );
}

export default Work;