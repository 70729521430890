import React from 'react';
import './styles.css';

function About() {
  return (
<div className="page">
  <header className="header">
    <div className="container"> 
      <nav className="navbar">
            <ul className="nav wrap">
               <li><a className="active" href="/">About</a></li>
               <li><a href="/work">Work</a></li>
               <li><a href="/clients">Clients</a></li>
               <li><a href="/contact">Contact</a></li>
               <li><a href="/privacypolicy">Privacy Policy</a></li>
            </ul>
      </nav>
    </div>
  </header>      
  <div className="clear"></div>

  <section className="background" style="padding:20px;">
    <div className="container">
      <h3>Brett O'Connor Designs</h3>
      <div className="row row-2">
        <div className="col col-2">
          <article className="content">
            <div className="contentbody">
            <img src="images/brettaoconnor.jpg" alt="Brett O'Connor" width="300" height="375" align="left" styleName="padding:10 10 10 10;"/><br />
              <p className="in">I am a junior front end developer currently in the market for a challenging and creative position. I have a Bachelor of Science in Information Technology with a concentration in Multimedia Design and Visual Communication. I am skilled in using JavaScript, jQuery, XML, HTML5, CSS, Ajax, Adobe CS-Photoshop, Dreamweaver, and WordPress. I have a solid understanding of cross-browsing/cross-platform issues. I am effective in design. I am familiar with W3C standards and specifications. I take pride in my creative work. My coding skills are impeccable. I work well in a group as well as independently. I consider myself personable and approachable. Please browse through my portfolio. Enjoy!<br></br><a href="http://linkedin.com/in/brettoconnorwebdesigner"><img src="images/linkedin.jpg"  alt="LinkedIn"/></a></p>
            </div>
         </article>
        </div>
      </div>
    </div>
  </section>
  
  <footer className="footer">
    <div className="container">
      <div className="footer-inner">
        <p className="in2"><span>Brett O'Connor Designs</span>&nbsp;<span>&copy;</span>&nbsp;2019</p>
      </div>
    </div>
  </footer>
</div>
  );
}
    
export default About;