import React from 'react';
import './styles.css';

function Clients() {
  return (
    <div className="page">
    <header className="header">
       <div className="container"> 
         <nav className="navbar">
               <ul className="nav wrap">
                 <li><a href="/">About</a></li>
                 <li><a href="/work">Work</a></li>
                 <li><a className="active" href="/clients">Clients</a></li>
                 <li><a href="/contact">Contact</a></li>
                 <li><a href="/privacypolicy">Privacy Policy</a></li>
               </ul>
         </nav>
       </div>
     </header>      
     <div className="clear"></div>
     
     <section className="background" style="padding:20px;">
       <div className="container">
         <h3>Clients</h3>
         <div className="row row-2">
           <div className="col col-3">
             <article className="content">
             <a className="view" href="http://seanoconnor.caretakerdesigns.com/" target="new"><img src="images/sean.jpg" alt="client - sean o'connor" width="418" height="315" /></a>
               <div className="contenttitle">
                 <h5>Sean O'Connor</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
           <div className="col col-3">
             <article className="content">
             <a className="view" href="http://cargocollective.com/oconnor" target="new"><img src="images/seanoconnor.jpg" alt="client - sean o'connor" width="418" height="315" /></a>
               <div className="contenttitle">
                 <h5>Sean O'Connor</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
           <div className="col col-3">
             <article className="content">
             <img src="images/droconnor.jpg" alt="client - dr. o'connor" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Dr. O'Connor</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
         </div>
       </div>
     </section>
     
     <section className="background" style="padding:20px;">
       <div className="container">
         <div className="row row-2">
           <div className="col col-3">
             <article className="content">
             <img src="images/stage.jpg" alt="client - stage 1901" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Stage 1901</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
           <div className="col col-3">
             <article className="content">
             <img src="images/blueroom.jpg" alt="client - blue room" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Blue Room</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
           <div className="col col-3">
             <article className="content">
             <img src="images/blueroomhd.jpg" alt="client - blue room hd" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Blue Room HD</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
         </div>
       </div>
     </section>
     
     <section className="background" style="padding:20px;">
       <div className="container">
         <div className="row row-2">
           <div className="col col-3">
             <article className="content">
             <img src="images/biglogo.jpg" alt="client - big logo" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Big Logo</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
           <div className="col col-3">
             <article className="content">
             <img src="images/seanoc.jpg" alt="client - sean o'connor" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Sean O'Connor</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
           <div className="col col-3">
             <article className="content">
             <img src="images/drcaton.jpg" alt="client - dr. caton" width="418" height="315" />
               <div className="contenttitle">
                 <h5>Dr. Caton</h5>
               </div>
               <div className="subcontent">
                 <div className="subtitle">
                     <span>Client</span>
                 </div>
               </div>
             </article>
           </div>
         </div>
       </div>
     </section>
     
     <footer className="footer">
       <div className="container">
         <div className="footer-inner">
           <p className="in2"><span>Brett O'Connor Designs</span>&nbsp;<span>&copy;</span>&nbsp;2019</p>
         </div>
       </div>
     </footer>
</div>
  );
}

export default Clients;
